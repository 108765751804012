<template>
  <div>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">DAMAYAN - BOD ANNUAL SHARE</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-layout row wrap class="align-center mx-2 mt-5">
        <v-layout col>
          <v-flex xs12 md3>
            <v-select
              v-model="year_of_id"
              class="mx-2"
              dense
              outlined
              label="Year Of"
              :items="year_of_items"
              item-value="year"
              item-text="year"
              :rules="rules.combobox_rule"
              @change="selected_year"
            ></v-select>
            <v-tabs
              v-model="tab"
              show-arrows
            >
              <v-tab
                v-for="tab in tabs"
                :key="tab.title"
              >
                <v-icon
                  size="20"
                  class="me-3"
                >
                  {{ tab.icon }}
                </v-icon>
                <span>{{ tab.title }}</span>
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
              <v-tab-item>
                <h2>Total: P {{total_amount}}</h2>
                <v-data-table dense
                              :headers="headers"
                              :items="data_items"
                >
                  <template v-slot:item="{ item }">
                    <tr>
                      <td>
                        {{ item.name }}
                      </td>
                      <td>
                        {{ item.percentage }}
                      </td>
                      <td>
                        {{ item.total }}
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-tab-item>
              <v-tab-item>
                <h2>Total: P {{total_amount_dec}}</h2>
                <v-data-table dense
                              :headers="headers"
                              :items="data_items"
                >
                  <template v-slot:item="{ item }">
                    <tr>
                      <td>
                        {{ item.name }}
                      </td>
                      <td>
                        {{ item.percentage }}
                      </td>
                      <td>
                        {{ item.total_dec }}
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-tab-item>

            </v-tabs-items>
          </v-flex>
          <v-flex xs12 md9 class="mx-2">
            <v-tabs
              v-model="tab"
              show-arrows
            >
              <v-tab
                v-for="tab in tabs"
                :key="tab.title"
              >
                <v-icon
                  size="20"
                  class="me-3"
                >
                  {{ tab.icon }}
                </v-icon>
                <span>{{ tab.title }}</span>
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
              <v-tab-item>
                <v-data-table dense
                              :headers="headers3"
                              :items="data_items3"
                >
                  <template v-slot:item="{ item }">
                    <tr>
                      <td>
                        {{ item.branch}}
                      </td>
                      <td>
                        {{ item.dec}}
                      </td>
                      <td>
                        {{ item.jan}}
                      </td>
                      <td>
                        {{ item.feb}}
                      </td>
                      <td>
                        {{ item.mar}}
                      </td>
                      <td>
                        {{ item.apr}}
                      </td>
                      <td>
                        {{ item.may}}
                      </td>
                      <td>
                        {{ item.jun}}
                      </td>
                      <td>
                        {{ item.jul}}
                      </td>
                      <td>
                        {{ item.aug}}
                      </td>
                      <td>
                        {{ item.sep}}
                      </td>
                      <td>
                        {{ item.oct}}
                      </td>
                      <td>
                        {{ item.nov}}
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-tab-item>
              <v-tab-item>
                <v-data-table dense
                              :headers="headers3"
                              :items="data_items3"
                >
                  <template v-slot:item="{ item }">
                    <tr>
                      <td>
                        {{ item.branch}}
                      </td>
                      <td>
                        {{ item.dec_dec}}
                      </td>
                      <td>
                        {{ item.jan_dec}}
                      </td>
                      <td>
                        {{ item.feb_dec}}
                      </td>
                      <td>
                        {{ item.mar_dec}}
                      </td>
                      <td>
                        {{ item.apr_dec}}
                      </td>
                      <td>
                        {{ item.may_dec}}
                      </td>
                      <td>
                        {{ item.jun_dec}}
                      </td>
                      <td>
                        {{ item.jul_dec}}
                      </td>
                      <td>
                        {{ item.aug_dec}}
                      </td>
                      <td>
                        {{ item.sep_dec}}
                      </td>
                      <td>
                        {{ item.oct_dec}}
                      </td>
                      <td>
                        {{ item.nov_dec}}
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-tab-item>

            </v-tabs-items>

          </v-flex>
        </v-layout>
      </v-layout>
    </v-card>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {
    mdiAccountOutline,
    mdiAccountCashOutline,
    mdiInformationOutline,
    mdiGoogleCirclesCommunities
  } from "@mdi/js";
  import moment from "moment";
  import {mapActions, mapGetters} from "vuex";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'
  import category from "@/store/module/data/category";

  const initialState = () => {
    return {
      tab: '',
      tabs: [
        {title: 'CURRENT', icon: mdiGoogleCirclesCommunities},
        {title: 'DECLARED', icon: mdiAccountOutline},
      ],
      data_items: [],
      data_items3: [],
      year_of_items: [],
      headers: [
        {text: 'Name', value: 'date_of_deposit', sortable: false},
        {text: '%', value: 'tin', sortable: false},
        {text: 'Share', value: 'tin', sortable: false},
      ],
      headers3: [
        {text: 'Branch', value: 'date_of_deposit', sortable: false},
        {text: 'Dec', value: 'tin', sortable: false},
        {text: 'Jan', value: 'tin', sortable: false},
        {text: 'Feb', value: 'tin', sortable: false},
        {text: 'Mar', value: 'tin', sortable: false},
        {text: 'Apr', value: 'tin', sortable: false},
        {text: 'May', value: 'tin', sortable: false},
        {text: 'Jun', value: 'tin', sortable: false},
        {text: 'Jul', value: 'tin', sortable: false},
        {text: 'Aug', value: 'tin', sortable: false},
        {text: 'Sep', value: 'tin', sortable: false},
        {text: 'Oct', value: 'tin', sortable: false},
        {text: 'Nov', value: 'tin', sortable: false},
      ],
      total_amount: '0',
      total_amount_dec: '0',
      year_of_id: '',
    }
  }
  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiAccountOutline,
          mdiAccountCashOutline,
          mdiInformationOutline,
        },
      }
    },
    data() {
      return initialState()
    },
    mounted() {
      this.initialize_bod_annual_share_damayan()
        .then(response => {
          this.year_of_items = response.data
        })
        .catch(error => {
          console.log(error)
        })

    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('authentication', ['address', 'contact', 'name', 'department', 'position']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo', 'damayan', 'chapel', 'factory', 'coop', 'printing']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('bod_annual_share', ['initialize_bod_annual_share_damayan', 'yearly_data']),
      date_format(value) {
        return moment(value)
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      selected_year(value) {
        this.yearly_data({
          year: value
        })
          .then(response => {
            this.data_items = response.data[0].bod
            this.data_items3 = response.data[0].data
            this.total_amount = response.data[0].total
            this.total_amount_dec = response.data[0].total_dec
          })
          .catch(error => {
            console.log(error)
          })
      },
    }
  }
</script>
