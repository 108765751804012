var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-toolbar',{attrs:{"dense":"","dark":"","color":"primary"}},[_c('v-toolbar-title',[_c('h4',{staticClass:"font-weight-light"},[_vm._v("DAMAYAN - BOD ANNUAL SHARE")])])],1),_c('v-layout',{staticClass:"align-center mx-2 mt-5",attrs:{"row":"","wrap":""}},[_c('v-layout',{attrs:{"col":""}},[_c('v-flex',{attrs:{"xs12":"","md3":""}},[_c('v-select',{staticClass:"mx-2",attrs:{"dense":"","outlined":"","label":"Year Of","items":_vm.year_of_items,"item-value":"year","item-text":"year","rules":_vm.rules.combobox_rule},on:{"change":_vm.selected_year},model:{value:(_vm.year_of_id),callback:function ($$v) {_vm.year_of_id=$$v},expression:"year_of_id"}}),_c('v-tabs',{attrs:{"show-arrows":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.tabs),function(tab){return _c('v-tab',{key:tab.title},[_c('v-icon',{staticClass:"me-3",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(tab.icon)+" ")]),_c('span',[_vm._v(_vm._s(tab.title))])],1)}),1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('h2',[_vm._v("Total: P "+_vm._s(_vm.total_amount))]),_c('v-data-table',{attrs:{"dense":"","headers":_vm.headers,"items":_vm.data_items},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',[_vm._v(" "+_vm._s(item.name)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.percentage)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.total)+" ")])])]}}])})],1),_c('v-tab-item',[_c('h2',[_vm._v("Total: P "+_vm._s(_vm.total_amount_dec))]),_c('v-data-table',{attrs:{"dense":"","headers":_vm.headers,"items":_vm.data_items},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',[_vm._v(" "+_vm._s(item.name)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.percentage)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.total_dec)+" ")])])]}}])})],1)],1)],1),_c('v-flex',{staticClass:"mx-2",attrs:{"xs12":"","md9":""}},[_c('v-tabs',{attrs:{"show-arrows":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.tabs),function(tab){return _c('v-tab',{key:tab.title},[_c('v-icon',{staticClass:"me-3",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(tab.icon)+" ")]),_c('span',[_vm._v(_vm._s(tab.title))])],1)}),1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('v-data-table',{attrs:{"dense":"","headers":_vm.headers3,"items":_vm.data_items3},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',[_vm._v(" "+_vm._s(item.branch)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.dec)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.jan)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.feb)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.mar)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.apr)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.may)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.jun)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.jul)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.aug)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.sep)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.oct)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.nov)+" ")])])]}}])})],1),_c('v-tab-item',[_c('v-data-table',{attrs:{"dense":"","headers":_vm.headers3,"items":_vm.data_items3},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',[_vm._v(" "+_vm._s(item.branch)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.dec_dec)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.jan_dec)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.feb_dec)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.mar_dec)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.apr_dec)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.may_dec)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.jun_dec)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.jul_dec)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.aug_dec)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.sep_dec)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.oct_dec)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.nov_dec)+" ")])])]}}])})],1)],1)],1)],1)],1)],1),_c('snack-bar-dialog',{attrs:{"snackbar_flag":this.snackbar,"color":this.snackbar_color,"snackbar_text":this.snackbar_text}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }